import { WhatWeDoData, homepageFaqs } from "data/dummyData";

import AboutPage from "./About";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CompanyServicesPage from "pages/candidates/ServicePortfolio";
import FAQIMG from "images/ishaq.webp";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import GermanLanguagePage from "./GermanLanguage";
import LandingPage from "./LandingPage";
import LearnGerman from "images/learn-german.svg";
import React from "react";
import SEO from "components/seo/SEO";

// title, description, name, type

const HomePage = () => {
  return (
    <AnimationRevealPage>
      <SEO
        title="Weg2De - Study, Work &amp; do Ausbildung in Germany and Europe - Learn German Online at Weg2De Akademie"
        description="At Weg2De, we help you realise your dream of Studying, doing Apprenticeship (Ausbildung) and Working in Germany, Affordable German Language School Online, Learn German from home, Learn German online at Weg2De Akademie, Apprenticeship in Germany, Ausbildung in Germany, Work as Audiologist in Germany, Work in Germany, Learn German Online - Weg2De Akademie | Fächkrafte und Azubis aus dem Ausland finden - Weg2De |  Weg2De Ausbildung Praparatory Program Germany WAPP |  Working in Germany Weg2De | Best German language school in Germany - Weg2De Akademie | Best Online German Language School - Weg2De Akademie - Hörakustiker finden!, Ausbildung in Germany, weg2de.com"
        name="Weg2De Homepage"
        type=""
      />
      <LandingPage />
      <AboutPage />
      <CompanyServicesPage heading="What We Do" data={WhatWeDoData} />
      <GermanLanguagePage
        subheading="German Language"
        heading="Learn German with us Today"
        imageSrc={LearnGerman}
        showDecoratorBlob={true}
        textOnLeft={false}
      />
      <FaqPage
        subheading="FAQS"
        heading="Do you have Questions?"
        imageSrc={FAQIMG}
        faqs={homepageFaqs}
      />
      <FooterPage />
    </AnimationRevealPage>
  );
};

export default HomePage;
