import {
  HighlightedText,
  MainHeading,
  SectionHeading,
  Subheading,
} from "components/misc/Headings";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import { FaExternalLinkAlt } from "react-icons/fa";
import FooterPage from "components/Footer";
import InnerFaqPage from "./InnerFaqPage";
import { Link } from "react-router-dom";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { privacyFaqData } from ".";
import SEO from "components/seo/SEO"
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-32 relative max-w-screen-xl mx-auto`;
const Wrapper = tw.section`mt-10`;
const PolicyWrapper = tw.p`flex`;
const ListWrapper = tw.ul`ml-3 md:ml-5 list-disc marker:text-primary-500 text-gray-500 font-light text-sm md:text-lg`;
const ListItem = tw.li`mb-2`;
const Title = tw(SectionHeading)`text-lg md:text-2xl mt-10`;
const Info = tw(Subheading)`my-10`;
const LinkWrapper = tw(
  Link
)`flex items-center text-gray-500 font-light underline`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

function PrivacyStatementPage() {
  return (
    <AnimationRevealPage>
      
      <SEO
        title="Privacy Statement - Weg2De - Weg2De"
        description="Privacy Statement - Weg2De - German Online Group Course | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De"
        type=""
      />
      <Container>
        <MainHeading>
          Privacy <HighlightedText>Statement</HighlightedText>
        </MainHeading>
        <Wrapper>
          <SectionDescription>
            This privacy statement was last updated on{" "}
            <HighlightedText>May 10, 2024 </HighlightedText> and applies to
            citizens and legal permanent residents of the European Economic Area
            and Switzerland.
          </SectionDescription>
          <SectionDescription>
            In this privacy statement, we explain what we do with the data we
            obtain about you via{" "}
            <HighlightedText>https://weg2de.com.</HighlightedText> We recommend
            you carefully read this statement. In our processing we comply with
            the requirements of privacy legislation. That means, among other
            things, that:
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              we clearly state the purposes for which we process personal data.
              We do this by means of this privacy statement;
            </ListItem>
            <ListItem>
              we aim to limit our collection of personal data to only the
              personal data required for legitimate purposes;
            </ListItem>
            <ListItem>
              we first request your explicit consent to process your personal
              data in cases requiring your consent;
            </ListItem>
            <ListItem>
              we take appropriate security measures to protect your personal
              data and also require this from parties that process personal data
              on our behalf;
            </ListItem>
            <ListItem>
              we respect your right to access your personal data or have it
              corrected or deleted, at your request.
            </ListItem>
            <Info>
              If you have any questions, or want to know exactly what data we
              keep of you, please contact us.
            </Info>
          </ListWrapper>
          <Title>1. Purpose, data and retention period</Title>
          <SectionDescription>
            We may collect or receive personal information for a number of
            purposes connected with our business operations which may include
            the following: (click to expand)
          </SectionDescription>
          <InnerFaqPage faqs={privacyFaqData} />

          <Title>2. Cookies</Title>
          <SectionDescription>
            <PolicyWrapper>
              Our website uses cookies. For more information about cookies,
              please refer to our{" "}
              <LinkWrapper to="/cookie-policy" style={{ marginLeft: "5px" }}>
                <HighlightedText> Cookie Policy </HighlightedText>
                <HighlightedText>
                  <FaExternalLinkAlt size={10} style={{ marginLeft: "5px" }} />
                </HighlightedText>
              </LinkWrapper>
            </PolicyWrapper>
          </SectionDescription>
          <Title>3. Disclosure practices</Title>
          <SectionDescription>
            We disclose personal information if we are required by law or by a
            court order, in response to a law enforcement agency, to the extent
            permitted under other provisions of law, to provide information, or
            for an investigation on a matter related to public safety.
          </SectionDescription>
          <SectionDescription>
            If our website or organisation is taken over, sold, or involved in a
            merger or acquisition, your details may be disclosed to our advisers
            and any prospective purchasers and will be passed on to the new
            owners.
          </SectionDescription>
          <SectionDescription>
            The inclusion of full IP addresses is blocked by us.
          </SectionDescription>
          <Title>4. Security</Title>
          <SectionDescription>
            We are committed to the security of personal data. We take
            appropriate security measures to limit abuse of and unauthorized
            access to personal data. This ensures that only the necessary
            persons have access to your data, that access to the data is
            protected, and that our security measures are regularly reviewed.
          </SectionDescription>
          <Title>5. Third-party websites</Title>
          <SectionDescription>
            This privacy statement does not apply to third-party websites
            connected by links on our website. We cannot guarantee that these
            third parties handle your personal data in a reliable or secure
            manner. We recommend you read the privacy statements of these
            websites prior to making use of these websites.
          </SectionDescription>
          <Title>6. Amendments to this privacy statement</Title>
          <SectionDescription>
            We reserve the right to make amendments to this privacy statement.
            It is recommended that you consult this privacy statement regularly
            in order to be aware of any changes. In addition, we will actively
            inform you wherever possible.
          </SectionDescription>
          <Title>7. Accessing and modifying your data</Title>
          <SectionDescription>
            If you have any questions or want to know which personal data we
            have about you, please contact us. You can contact us by using the
            information below. You have the following rights:
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              You have the right to know why your personal data is needed, what
              will happen to it, and how long it will be retained for.
            </ListItem>
            <ListItem>
              Right of access: You have the right to access your personal data
              that is known to us.
            </ListItem>
            <ListItem>
              Right to rectification: you have the right to supplement, correct,
              have deleted or blocked your personal data whenever you wish.
            </ListItem>
            <ListItem>
              If you give us your consent to process your data, you have the
              right to revoke that consent and to have your personal data
              deleted.
            </ListItem>
            <ListItem>
              Right to transfer your data: you have the right to request all
              your personal data from the controller and transfer it in its
              entirety to another controller.
            </ListItem>
            <ListItem>
              Right to object: you may object to the processing of your data. We
              comply with this, unless there are justified grounds for
              processing.
            </ListItem>
          </ListWrapper>
          <SectionDescription>
            Please make sure to always clearly state who you are, so that we can
            be certain that we do not modify or delete any data of the wrong
            person.
          </SectionDescription>
          <Title>8. Submitting a complaint</Title>

          <SectionDescription>
            If you are not satisfied with the way in which we handle (a
            complaint about) the processing of your personal data, you have the
            right to submit a complaint to the Data Protection Authority.
          </SectionDescription>

          <Title>9. Contact details</Title>
          <address>
            Weg2De Akademie
            <br />
            Juliusstr. 11
            <br />
            42105, Wuppertal<br />
            Germany <br />
            Website:{" "}
            <HighlightedText>
              <a href="https://weg2de.com" target="_blank" rel="noreferrer">
                https://weg2de.com
              </a>
            </HighlightedText>
            <br />
            Email: akademie@weg2de.com <br />
            Phone number: +4917660909108
          </address>
        </Wrapper>
        <DecoratorBlob1 />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default PrivacyStatementPage;
