import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
import { Link } from "react-router-dom";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import LogoImage from "images/logo-white.png";
import React from "react";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ReactComponent as TwitterIcon } from "images/xicon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
//eslint-disable-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.footer`relative bg-secondary-500 text-white mt-5 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.section`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.section`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const NavLink = tw(Link)`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;
const ExternalLink = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-100 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 tracking-wider font-light text-white`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-white text-gray-900 hover:bg-gray-200 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-secondary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-secondary-700 opacity-50`;

function FooterPage() {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
            {/*<LinkListItem>
                <NavLink to="/our-team">Our Team</NavLink>
              </LinkListItem>*/}
              <LinkListItem>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </LinkListItem>
              <LinkListItem>
                <ExternalLink href="https://blog.weg2de.com" target="_blank">Weg2De Blog</ExternalLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="/faqs">FAQs</NavLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Candidates</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <NavLink to="/apprenticeship-program">Ausbildung in Germany</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="/work-and-live-in-germany">Work in Germany</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="/university-admission">Study in Germany</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="/german-health-insurances">German Insurance</NavLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Arbeitgeber</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <NavLink to="/arbeitgeber">Fachkräfte Finden</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="#">Chancenkarte</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="#">Beschleunigte FV</NavLink>
              </LinkListItem>
              <LinkListItem>
                <NavLink to="/contact-us">Ko-operation</NavLink>
              </LinkListItem>

            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </LinkListItem>
              <LinkListItem>
                <Link to="/privacy-statement">Privacy Statement</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/disclaimer">Disclaimer</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to="/impressum">Impressum</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} style={{ width: "200px" }} />
          </LogoContainer>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} Weg2De |
            All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/weg2de" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://x.com/weg2de" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/weg2de" target="_blank">
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/weg2de"
              target="_blank"
            >
              <LinkedInIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@weg2de" target="_blank">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
}

export default FooterPage;
